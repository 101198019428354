import React from 'react'
import Link from 'gatsby-link'
import SVG  from 'react-inlinesvg'
import Logo from '../images/zylo-icon.svg'
import illustrationSVG from '../images/home-illustration.svg'

const Footer = () => {
  let isHome
  if (typeof window !== 'undefined') {
    isHome = window.location.pathname === '/' ? true : false
  }

  return (
    <footer className="footer">
      { isHome ?
        (
          <SVG className="home-illustration"
               src={illustrationSVG} />
        ) : (
          <Link to="/" className="zylo-icon">
            <SVG src={Logo} />
          </Link>
        )
      }
    </footer>
  )
}

export default Footer
