import React from 'react'
import IosSendOutline from 'react-ionicons/lib/IosSendOutline'
import Layout from '../components/Layout'

const ContactPage = () => (
  <Layout>
    <div className="contact-page">
      <h1>Talk to me.</h1>

      <form className="contact-form" action="https://formspree.io/zylo.codes@gmail.com" method="POST">
        <label htmlFor="name">Name *</label>
        <input type="text" name="name" placeholder="Name" required />

        <label htmlFor="email">Email *</label>
        <input type="email" name="_replyto" placeholder="Email" required />

        <label htmlFor="phone">Phone</label>
        <input type="tel" name="phone" placeholder="Phone" />

        <label htmlFor="interest">Message *</label>
        <textarea name="message" placeholder="Message" required></textarea>

        <input type="hidden" name="_subject" value="Zylo site submission!" />
        <input type="hidden" name="_next" value="//zylocod.es/thanks" />
        <input type="text" name="_gotcha" style={{display:'none'}} />
        <button className="btn btn-green btn-lg" type="submit">
          Talk to me <IosSendOutline />
        </button>
      </form>
    </div>
  </Layout>
)

export default ContactPage
