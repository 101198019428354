import React from 'react'
import Helmet from 'react-helmet'
import NavBar from './NavBar'
import PropTypes from 'prop-types'
import ScrollToTop from 'react-scroll-up'
import UpArrow from '../images/up-arrow.svg'
import Footer from './Footer'

const Layout = ({ children }) => (
    <div>
      <Helmet
        title="Zylo | Web Development"
        meta={[
          { name: 'description', content: 'web development, web developer, programmer, illustrator, freelance' },
          { name: 'keywords', content: 'web, web development, graphic design, app development, programming, iot, vr, unity, web apps' },
        ]}
      />

      <NavBar />

      <div className="content">
        { children }
      </div>

      <ScrollToTop showUnder={160} style={{bottom: 10}}>
        <img src={UpArrow} className="up-arrow" alt="scroll up" />
      </ScrollToTop>

      <Footer />
    </div>
  )

Layout.propTypes = {
  children: PropTypes.func,
}

export default Layout
